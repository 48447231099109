@import 'components/mixins.scss';

@import 'scss/main';

:global(.searchWrapper) {
  position: relative;

  :global(.searchBox) {
    position: relative;
    border-radius: 8px;

    &:global(.extraElem) {
      input {
        padding-left: 40px;
      }

      input + button {
        position: absolute;
        left: 22px;
        top: calc(50% - 16px);
      }
    }

    input {
      height: 36px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #262626;
      background-color: #fbfbfb;
      border: solid 1px #d6d6d6;
      border-radius: 8px;

      &::placeholder {
        color: #8f96a0;
      }
    }

    :global(.ant-input-group-addon) {
      background-color: transparent;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: solid 1px #d6d6d6 !important;
      border-left: none !important;

      button {
        padding: 0 10px;
        border: none;
        background-color: transparent !important;
      }
    }
  }

  :global(.searchBox) + button {
    width: 100%;
    height: 40px;
  }

  :global(.searchMenu) {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 32px;
    vertical-align: top;
    padding-left: 8px;

    :global(.ant-menu) {
      height: 32px;
      line-height: 32px;
      border-bottom: none;

      > :global(.ant-menu-overflow-item) {
        padding: 0 2px;

        img {
          width: 22px;
          height: 22px;
        }
      }

      :global(.ant-menu-title-content):empty {
        margin-left: 0;
      }
    }
  }
}
