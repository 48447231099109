@import 'components/mixins.scss';

.languageSwitcher.dropdown {
  // color: $gray-5;
  // font-style: normal;
  // font-weight: 400;
  // font-size: 16px;
  // line-height: 20px;
  // color: #646464;
  display: inline-block;
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  @include transition-slow;

  &::after {
    opacity: 0.5;
    color: $gray-5;
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    display: inline-block;
    content: '';
    width: 8px; /* 사이즈 */
    height: 8px; /* 사이즈 */
    border-top: 1.5px solid #646464; /* 선 두께 */
    border-right: 1.5px solid #646464; /* 선 두께 */
    transform: rotate(135deg);
    @include transition-slow;
  }

  &:hover {
    color: $blue;

    &::after {
      color: $gray-6;
    }
  }
}

// dark theme
[data-kit-theme='dark'] {
  .languageSwitcher.dropdown {
    color: $dark-gray-2;

    &:hover {
      color: $primary;
    }
  }
}
