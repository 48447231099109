@import 'components/mixins.scss';

@import 'gql/ninegqlUI/mixins';

.channelList {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // background-color: #fff;

  .channelSearch {
    margin-bottom: 10px;

    > :global(.ant-input-search) {
      margin: 0;
    }
  }

  .channelsWrap {
    max-height: calc(100% - 55px);
    height: calc(100% - 55px);

    :global(.ant-list-item) {
      border-bottom: none;
    }
  }

  &:global(.showSelection) {
    :global(.ant-list-item.selected) {
      background-color: #e7efff;
    }
  }

  :global(.ps__rail-x) {
    display: none;
  }

  :global(.ant-list-item) {
    padding: 3px 0;

    &:hover {
      background-color: #fbfbfb;

      .roomItem .joinBtn {
        display: block;
        border: none;
      }
    }
  }
}

.roomItem {
  position: relative;
  width: 100%;
  padding-left: 36px;
  padding-right: 0;
  color: #000;
  font-family: 'Spoqa Han Sans Neo', Arial, Helvetica, sans-serif;
  font-size: 14px;

  :global(.roomIcon) {
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    @include squircle-mask;
  }

  .content {
    position: relative;
    height: 30px;
    display: flex;
    flex-direction: row;
  }

  .roomName {
    font-size: 14px;
    color: #262626;
    line-height: 30px;
    vertical-align: middle;
    max-width: calc(100% - 175px);
    margin-right: 8px;
    @include ellipsis(1);
  }

  .members {
    font-size: 12px;
    line-height: 30px;
    vertical-align: middle;
    width: 110px;
    color: #b9babc;

    :global(.joined) {
      color: #62bc00;
    }
  }

  .joinBtn {
    display: none;
    position: absolute;
    top: -1px;
    right: 0;
    height: 32px !important;
    border-radius: 16px !important;
    background-color: #9b9b9b !important;
    color: #fff !important;
    padding: 4px 16px !important;
  }
}
