@import 'components/mixins.scss';

.dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;

  // &::after {
  //   opacity: 0.5;
  //   color: $gray-5;
  //   position: absolute;
  //   top: 50%;
  //   right: 0;
  //   margin-top: -2px;
  //   display: inline-block;
  //   width: 0;
  //   height: 0;
  //   margin-left: 0.255em;
  //   vertical-align: 0.255em;
  //   content: '';
  //   border-top: 0.3em solid;
  //   border-right: 0.3em solid transparent;
  //   border-bottom: 0;
  //   border-left: 0.3em solid transparent;
  //   @include transition-slow();
  // }

  &:hover {
    color: $blue;

    &::after {
      color: $gray-6;
    }
  }
}

.avatar:global(.ant-avatar) {
  background-color: $gray-2 !important;
  @include squircle-mask;
}

// :global(.menu-language) {
//   > :global(.label) {
//     margin-right: 10px;
//   }
// }

// dark theme
[data-kit-theme='dark'] {
  .avatar {
    background: $dark-gray-4 !important;
  }
}

.userMenu {
  :global(.ant-dropdown-menu-title-content) {
    display: inline-block;
    width: min(100%, 420px);
    max-width: 420px;

    > :global(.member-container) {
      margin-bottom: 24px;
    }
  }
}
