@import 'components/mixins.scss';

@import 'scss/main';

:global(.member-container) {
  background-color: #fff;
  position: relative;
  padding: 0 0 0 152px;
  min-height: 128px;
  cursor: default;

  :global(.avatar) {
    position: absolute;
    left: 0;
    top: 0;
    width: 128px;
    height: 128px;
    @include squircle-mask;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  :global(.name) {
    font-size: 20px;
    font-weight: bold;
    height: 22px;
    line-height: 22px;
    margin-bottom: 4px;
    max-width: 100%;
    @include ellipsis(1);
  }

  :global(.role) {
    font-size: 14px;
    height: 16px;
    line-height: 16px;
    color: #646464;
    margin-bottom: 4px;
    @include ellipsis(1);
  }

  :global(.email) {
    font-size: 14px;
    line-height: 16px;
    color: #646464;
    @include ellipsis(2);
  }

  :global(.info) {
    min-width: 150px;
    padding-top: 4px;

    :global(.extra-container) {
      position: absolute;
      left: 152px;
      bottom: 0;
      right: 0;
      height: 36px;
      display: flex;
      align-items: center;

      > * {
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }

      button:global(.ant-btn-lg) {
        // min-width: 124px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 16px;
        height: 36px;
        border-radius: 8px;
        color: #262626;
        border: solid 1px #d6d6d6;

        &:global(.ant-btn-primary) {
          color: #fff;
          background-color: #3578f7 !important;
          border: solid 1px #3578f7 !important;
        }

        &[disabled] {
          color: #fff;
          background-color: #aaa8 !important;
          border: solid 1px #aaa8 !important;
        }
      }

      :global(.iconLink) {
        width: 32px;
        height: 32px;
        margin-left: 18px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
