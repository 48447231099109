@import 'components/mixins.scss';

@import 'scss/main';

.ftIcon,
:global(.ftIcon) {
  position: relative;
  display: inline-block;

  > img {
    width: 32px;
  }

  > :global(.type) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #727272;
  }

  &:global(.hideText) > :global(.type) {
    display: none;
  }
}
