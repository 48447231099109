// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'src/components/kit/core/mixins.scss'; // import KIT mixins
@import 'src/components/cleanui/styles/mixins.scss'; // import CLEANUI mixins

// $text: #000;
// $success: green;

@mixin ellipsis($line-cnt) {
  // white-space: nowrap;
  // line-height: $line-height;
  // height: $line-height * $line-cnt; /* line-height 가 1.2em 이고 3라인을 자르기 때문에 height는 1.2em * 3 = 3.6em */
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  @if $line-cnt == 1 {
    word-break: break-all;
  }

  // display: box;
  // line-clamp: $line-cnt; /* 라인수 */
  // box-orient: vertical;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: $line-cnt; /* 라인수 */
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: $line-cnt; /* 라인수 */
  -moz-box-orient: vertical;
  /* stylelint-enable */
}

@mixin squircle-mask() {
  /* stylelint-disable */
  -webkit-mask: url('/assets/images/squircle-mask.svg') 0 0 / 100% 100%;
  /* stylelint-enable */
  mask: url('/assets/images/squircle-mask.svg') 0 0 / 100% 100%;
  border-radius: 0 !important;
}
