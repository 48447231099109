@import 'components/mixins.scss';

@import 'scss/main';

.topbar {
  padding: 0 rem(30);
  min-height: 80px;
  height: 80px;
  border-bottom: 1px solid $border;
  color: $text;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  :global(.empty-text) {
    display: inline-block;
    line-height: 20px;
    width: 1px;
    border-left: solid 1px #cfcfcf;
  }

  @include respond-to('mobile') {
    min-height: 60px;
    height: 60px;
    padding: 0 rem(16) 0 54px;
  }

  :global(.mr-4) {
    margin-right: 32px !important;

    @include respond-to('mobile') {
      margin-right: 10px !important;
    }
  }

  :global(.last) > div {
    @include respond-to('mobile') {
      padding-right: 0;
    }
  }

  > div > div {
    height: 40px;

    > * {
      height: 40px;
      vertical-align: top;
    }
  }
}

// dark theme
[data-kit-theme='dark'] {
  .topbar {
    background: $dark-gray-6;
    color: $dark-gray-1;
    border-bottom: 1px solid $dark-gray-4;
  }
}
