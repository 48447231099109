@import 'components/mixins.scss';

@import 'scss/main';

// main layout

.mobileMenu {
  :global(.ant-drawer-content),
  :global(.ant-drawer-wrapper-body) {
    position: relative;
    overflow: visible !important;
    pointer-events: all;
  }

  :global(.ant-drawer-body) {
    padding: 0 !important;
    pointer-events: all;
    height: 100%;
    font-size: 0;
  }

  :global(.ant-layout-sider) {
    display: inline-block;
    height: 100%;
  }

  :global(.menuPortal) {
    position: relative;
    display: inline-block;
    width: calc(100% - 80px);
    height: 100%;

    :global(.portalWrapper) {
      position: relative;
      height: 100%;
    }
  }
}

.handler {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001529;
  border-radius: 0 4px 4px 0;
  /* stylelint-disable-next-line color-function-notation */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 15%);

  @include respond-to('mobile') {
    top: 0;
    height: 60px;
    width: 60px;
    z-index: 11;
    border-radius: 0;
  }
}

.handlerIcon {
  width: 14px;
  height: 2px;
  background: #fff;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: #fff;
    width: 100%;
    height: 2px;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }

  &:before {
    top: -5px;
  }

  &:after {
    top: 5px;
  }
}
