@import 'components/mixins.scss';

@import 'scss/main';

:global(.ant-layout) {
  position: relative;

  @include respond-to('mobile') {
    min-height: 0 !important;
  }

  // @include respond-to('mobile') {
  //   .header {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     height: 50px;
  //     z-index: 10;
  //   }

  //   .header + * {
  //     margin-top: 50px;
  //   }
  // }
}

:global(.ant-layout-sider.left-sider-menu) {
  background-color: #eee !important;
  flex: 0 0 78px !important;
  max-width: 78px !important;
  min-width: 78px !important;
  width: 78px !important;

  + :global(.ant-layout-content) {
    background-color: #eee !important;
    flex: 1 1 653px !important;
    max-width: 100% !important;
    min-width: unset !important;
    width: auto !important;

    > :global(.cui__utils__content) {
      max-width: 100% !important;
      padding: 0;
    }
  }
}

:global(.layout1110) {
  @include respond-to('mobile') {
    position: relative;
    height: 100%;
  }

  :global(.cui__layout__appMaxWidth) {
    max-width: 1486px;

    @include respond-to('mobile') {
      position: relative;
      height: 100%;
    }
  }

  :global(.ant-layout-has-sider) {
    min-height: calc(100vh - 80px) !important;

    @include respond-to('mobile') {
      min-height: calc(100vh - 60px) !important;
    }
  }

  :global(.ant-layout-header) {
    position: relative;
    background-color: #fff !important;
    height: 80px;
    max-height: 80px;
    line-height: 80px;

    @include respond-to('mobile') {
      height: 60px;
      max-height: 60px;
      line-height: 60px;
    }

    > :global(.logo) {
      display: inline-block;
      position: absolute;
      top: 16px;
      left: 16px;
      height: 48px;
      font-size: 20px;
      max-width: calc(100% - 295px);
      vertical-align: middle;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 48px;
      letter-spacing: normal;
      color: #262626;

      .logoImg {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        vertical-align: top;
      }

      .nameWrap {
        display: inline-block;
        max-width: calc(100% - 64px);

        .name {
          word-break: break-all;
          @include ellipsis(1);
        }
      }

      @include respond-to('mobile') {
        display: none;
      }
    }
  }
}
